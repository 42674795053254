<template>
  <router-view></router-view>
</template>
<script>
// import { onMounted } from "vue";
export default {
  name: "App",
  // setup() {
  //   function resizeFun() {
  //     let docEle = document.documentElement,
  //       resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
  //       resizeFun = () => {
  //         let clientWidth = docEle.clientWidth;
  //         if (!clientWidth) return;
  //         // 100是换算系数，相当于1rem=100px
  //         //1920是作为设计图的基准，我这是按照1920*1080的分辨率，可自行修改
  //         docEle.style.fontSize = 192 * (clientWidth / 1920) + "px"; //按照1920来，可自行修改  ，vue.config.js
  //       };
  //     if (!document.addEventListener) return;
  //     window.addEventListener(resizeEvt, resizeFun, false);
  //     window.addEventListener("DOMContentLoaded", resizeFun, false);
  //     resizeFun();
  //   }
  //   onMounted(resizeFun);
  //   return {};
  // },
};
</script>

<style>
html,
body {
  padding: 0;
  margin: 0;
  overflow: auto !important;
}
#app {
  font-family: SourceHanSansSC; /* 使用 SourceHanSansSC 字体 */
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
  /* margin-top: 60px; */
}
</style>
