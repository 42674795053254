import { createStore } from 'vuex'
import login from './login'
import home from './home'
import story from './story'
import books from './books'
const store = createStore({
  modules: {
    login,
    home,
    story,
    books
  }
})
export default store
