const state = {
  storyInfo: [],
  storyId: 0,
};
const mutations = {
  saveStoryList(state, data) {
    state.storyInfo = data;
  },
  storyItemId(state, data) {
    state.storyId = data;
  },
};
const actions = {};
const getters = {};
export default {
  state,
  mutations,
  actions,
  getters,
};
