const state = {
  id: "", //书籍id
  valueInfo: "", //书籍文本
  oldValueInfo: "", //用于暂时存储数据
  outline: "", //用于存储专业模式大纲
  textTitleName: "", //用于保存文本标题
};
const mutations = {
  textValue(state, data) {
    state.valueInfo = data;
    // console.log(state, '这是vuex数据')
  },
  oldVal(state, data) {
    state.oldValueInfo = data;
  },
  textValueId(state, data) {
    state.id = data;
  },
  outlineValue(state, data) {
    state.outline = data;
  },
  textTitle(state, data) {
    state.textTitleName = data;
  },
};
const actions = {};
const getters = {};
export default {
  state,
  mutations,
  actions,
  getters,
};
