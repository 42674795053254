const state = {
  bookId: '', //书籍id
  valueInfo: '', //书籍文本
  booksArrayList: [], //数组源数据，需要保存到后端
  selectId: '' //这是需要确定选中的是那个id
}
const mutations = {
  booksValue(state, data) {
    state.valueInfo = data
  },
  booksId(state, data) {
    state.bookId = data
  },
  booksArray(state, data) {
    state.booksArrayList = data
  },
  selectionId(state, data) {
    state.selectId = data
  }
}
const actions = {}
const getters = {}
export default {
  state,
  mutations,
  actions,
  getters
}
