const state = {
  userinfo: {}
}
const mutations = {
  userInformation(state, data) {
    state.userinfo = data
    //console.log(state.userinfo, '这是vuex数据')
  }
}
const actions = {}
const getters = {}
export default {
  state,
  mutations,
  actions,
  getters
}
